import Button from 'common/components/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { useAuth } from 'modules/authentication/AuthProvider'
import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useApi } from 'modules/api/ApiProvider'

export default function RegistrationAcceptTerms() {
    const [acceptTerms, setAcceptTerms] = useState(false)
    const { authclient: { updateUser } } = useApi()
    const { user, setUser } = useAuth()
    const history = useHistory()

    function handleAcceptTerms() {
        let updateduser = user
        updateduser.termsLastAccepted = new Date()
        updateUser(updateduser)
            .then(res => {
                setUser(res)
                localStorage.removeItem('registrationcreateprofile')
                localStorage.removeItem('registrationemail')
                history.push('/properties')
            })
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>Please Accept the Terms and Conditions</div>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={acceptTerms}
                        name="acceptTerms"
                        color="primary"
                        onChange={ (e) => setAcceptTerms(e.target.checked) }
                    />
                }
                label="I accept the terms and conditions"
            />
            <Button styletype='primary' onClick={handleAcceptTerms}>Next</Button>
        </div>
    )
}
