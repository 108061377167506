import { Switch } from 'react-router-dom'
import { LayoutRoute, PrivateRoute } from 'common/components/Routing'
import RegistrationCreateProfile from 'modules/user/RegistrationCreateProfile'
import RegistrationVerifyEmail from 'modules/user/RegistrationVerifyEmail'
import RegistrationAcceptTerms from 'modules/user/RegistrationAcceptTerms'

export default function RegistrationWizardSwitch(props) {
    return (
        <Switch>
            <LayoutRoute
                path={RegistrationWizardSteps[0].path}
                component={<RegistrationVerifyEmail onBack={() => props.onBack()} openSnackbar={props.openSnackbar} />}
            />
            <LayoutRoute
                path={RegistrationWizardSteps[1].path}
                component={<RegistrationCreateProfile onBack={() => props.onBack()} openSnackbar={props.openSnackbar} />}
            />
            <PrivateRoute
                path={RegistrationWizardSteps[2].path}
                component={<RegistrationAcceptTerms onBack={() => props.onBack()} openSnackbar={props.openSnackbar} />}
            />
        </Switch>
    )
}

export const RegistrationWizardSteps = [{
    path: '/registration/email',
    label: 'Verify Email',
}, {
    path: '/registration/profile',
    label: 'Create Profile',
}, {
    path: '/registration/accept-terms',
    label: 'Terms & Conditions',
}]
