import { useState, useEffect } from 'react'
import { useApi } from 'modules/api/ApiProvider'

export function useProperty(id) {
    const [property, setProperty] = useState({
        property: {
            id,
            name: '',
            contracts: []
        },
        error: null
    })
    const { propertyclient: { getProperty } } = useApi()

    useEffect(() => {
        let active = true
        
        if (id !== null) {
            getProperty(id)
                .then(property => {
                    if (active)
                        setProperty({
                            property: {
                                id: property.id,
                                name: property.name,
                                contracts: []
                            },
                            error: null
                        })
                })
                .catch(error => {
                    if (active)
                        setProperty({ property: { id, name: '', contracts: [] }, error })
                })
        }

        return () => { active = false }
    }, [id, getProperty])

    return property
}
