import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
} from 'react-router-dom'

import { LayoutRoute, PrivateRoute } from 'common/components/Routing'
import AuthenticatedLayout from 'common/layouts/AuthenticatedLayout'
import RegistrationWizard from 'pages/RegistrationWizard'
import PropertiesRoutes from 'pages/Properties'
import Profile from 'pages/Profile'
import ContractsRoutes from 'pages/Contracts'
import Landing from 'pages/Landing'
import AuthenticationRedirection from './AuthenticationRedirection'

export default function Root() {
    return (
        <Router>
            <AuthenticationRedirection />
            <Switch>
                <LayoutRoute exact path='/' component={<Landing />} />
                <LayoutRoute path='/registration' component={<RegistrationWizard />} />
                <PrivateRoute path='/properties' layout={AuthenticatedLayout} component={<PropertiesRoutes />} />
                <PrivateRoute path='/profile' layout={AuthenticatedLayout} component={<Profile />} />
                <PrivateRoute path='/contracts' layout={AuthenticatedLayout} component={<ContractsRoutes />} />
            </Switch>
        </Router>
    )
}
