import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider, CssBaseline } from '@material-ui/core'

import 'index.scss'
import AppRoot from 'root/Root'
import reportWebVitals from './reportWebVitals'
import theme from 'materialUiTheme'
import AuthProvider from 'modules/authentication/AuthProvider'
import ApiProvider from 'modules/api/ApiProvider'
import FirebaseProvider from 'modules/authentication/FirebaseProvider'

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <FirebaseProvider>
                <AuthProvider>
                    <ApiProvider>
                        <AppRoot />
                    </ApiProvider>
                </AuthProvider>
            </FirebaseProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
