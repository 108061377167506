import CircularProgress from "@material-ui/core/CircularProgress"
import MuiButton from "@material-ui/core/Button"
import TextField from "common/components/TextField"
import { useState, memo } from "react"
import { useHistory } from "react-router-dom"

import Button from 'common/components/Button'
import usePersistentState from "common/hooks/usePersistentState"
import { useAuth } from "modules/authentication/AuthProvider"
import useEmailSignInLink from "./useEmailSignInLink"
import useForm from "common/hooks/useForm"
import { useApi } from "modules/api/ApiProvider"

const RegistrationVerifyEmail = memo(function RegistrationVerifyEmail({ onBack }) {
    const { authclient: { sendEmailSignInLink } } = useApi()
    const { user } = useAuth()
    const history = useHistory()
    const [email, setRegistrationEmail] = usePersistentState('registrationemail', user.email ?? '')
    const [emailverificationsent, setEmailVerificationSent] = useState(false)
    const [accountexistserror, setAccountExistsError] = useState(null)

    // If the user just came through with an email verification link, sign in with the provided link
    const getQueryStringValue = (key) => decodeURIComponent(
        window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\\.\\+\\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")
    )
    const urlissigninlink = getQueryStringValue('signinlink') === 'true'

    const fields = {
        'Email': {
            default: email,
            validations: [
                { required: true },
                { regex: "(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|\"(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21\\x23-\\x5b\\x5d-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])*\")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\\x01-\\x08\\x0b\\x0c\\x0e-\\x1f\\x21-\\x5a\\x53-\\x7f]|\\\\[\\x01-\\x09\\x0b\\x0c\\x0e-\\x7f])+)\\])" }
            ]
        }
    }
    const action = urlissigninlink && !email ? updateEmail : sendVerification
    const { values, errors, handleChange, handleSubmit } = useForm(action, fields)

    // Anytime the email or url path changes, check to see if we have a valid sign in URL
    const { loading, signinlinkexpired } = useEmailSignInLink(email)

    // // If the user is authenticated, but does not have a password, redirect to profile update page
    // if (user.auth?.emailVerified && !user.auth?.hasPassword)
    //     return <Redirect to='/registration/profile' />

    // if (user.auth?.emailVerified && user.auth?.hasPassword && !user.termsLastAccepted)
    //     return <Redirect to='/registration/accept-terms' />

    // // If the user is already authenticated and has a verified email, redirect
    // if (user.auth?.emailVerified && user.auth?.hasPassword && user.termsLastAccepted)
    //     return <Redirect to='/properties' />

    // Verify
    function sendVerification() {
        setRegistrationEmail(values['Email'])
        sendEmailSignInLink(values['Email'])
            .then(res => {
                setAccountExistsError(null)
                setEmailVerificationSent(true)
            })
            .catch(err => {
                if (err.code === 409)
                    setAccountExistsError('An account already exists with the email entered')
            })
    }

    // Sign In
    function updateEmail() {
        setRegistrationEmail(values['Email'])
    }

    function renderButton() {
        const text = !email
            ? (urlissigninlink ? 'Confirm Email' : 'Send Email Verification')
            : ((emailverificationsent || signinlinkexpired) ? 'Resend Email Verification' : 'Send Email Verification')

        return (
            <Button onClick={handleSubmit}>
                {text}
            </Button>
        )
    }

    function renderDescription() {
        if (!email && !signinlinkexpired && !urlissigninlink)
            return 'You will receive an email verification to complete registration.'
        else if (!email && urlissigninlink)
            return 'Please confirm your email address to verify.'
        else if (!emailverificationsent && !signinlinkexpired)
            return 'You will receive an email verification to complete registration.'
        else if (emailverificationsent)
            return 'An email has been sent to ' + email
        else if (!emailverificationsent && signinlinkexpired)
            return 'It looks like your verification link has expired.'
    }

    return loading ? <CircularProgress></CircularProgress> : (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <p>{ renderDescription() }</p>
            <TextField
                label="Email"
                type="email"
                name="Email"
                value={values['Email'] || email}
                errormessage={errors.first('Email') ?? accountexistserror}
                onChange={handleChange}
            />
            {renderButton()}
            <Button styletype='text' onClick={() => { localStorage.removeItem('registrationemail'); onBack() }}>Back</Button>
        </div>
    )
})

export default RegistrationVerifyEmail
