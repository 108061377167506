import Grid from '@material-ui/core/Grid'
import TextField from 'common/components/TextField'
import Button from 'common/components/Button'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'
import useStyles from './Landing.styles'

import { useAuth } from 'modules/authentication/AuthProvider'
import { useApi } from 'modules/api/ApiProvider'

export default function Landing() {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [emailerror, setEmailError] = useState(null)
    const [passworderror, setPasswordError] = useState(null)
    const history = useHistory()
    const { authclient: { signInWithEmailAndPassword } } = useApi()
    const { setUser } = useAuth()

    function handleLogin() {
        setEmailError(null)
        setPasswordError(null)
        signInWithEmailAndPassword(email, password)
            .then(user => {
                setUser(user)
                history.push('/properties')
            })
            .catch(err => {
                switch (err.code) {
                    case 'auth/invalid-email':
                        setEmailError('Email entered is not valid')
                        break
                    case 'auth/user-disabled':
                        setEmailError('User account associated with the email has been disabled')
                        break
                    case 'auth/user-not-found':
                        setEmailError('No account was found with the email entered')
                        break
                    case 'auth/wrong-password':
                    default:
                        setPasswordError('Incorrect password for the provided email')
                        break
                }
            })
    }

    const styles = useStyles()

    return (
        <Grid container alignItems="stretch" style={{ minHeight: '100vh', backgroundColor: 'white' }}>
            {/* Left Side Login */}
            <Grid container item direction="column" xs={4}>
                <Box p={3}  flexGrow="1" display="flex" flexDirection="column">
                    <img
                        src='/darkLogo_xl.png'
                        width={155}
                        height={28}
                        alt='Onboard RS Logo'
                    />
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        marginTop="7em"
                        flexGrow="1"
                    >
                        <h3>Get Started</h3>
                        <TextField
                            label="Email"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            errormessage={emailerror}
                        />
                        <TextField
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            errormessage={passworderror}
                        />
                        <Button onClick={handleLogin}>Login</Button>
                        <Button styletype='text' className={styles.textButton}>Forgot Password?</Button>
                        <Button styletype='text' className={styles.textButton} onClick={() => history.push('/registration/email')}>Sign Up</Button>
                    </Box>
                </Box>
            </Grid>

            {/* Right Side Image */}
            <Grid item xs={8}>
                <Box px={15} pt={20} display="flex" flexDirection="column" height="100%" component={Paper} style={{backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundImage: "linear-gradient(135deg, rgba(254, 117, 83, 0.95), rgba(227, 79, 115, .95)), url('client_portal.jpg')"}}>
                    <span style={{color: 'white', fontSize: '20px', fontWeight: 'bold'}}>WELCOME TO</span>
                    <span style={{color: 'white', fontSize: '40px', fontWeight: 'bold', marginTop: '20px'}}>Prism Contract Management</span>
                    <hr align="left" size="10" style={{color: 'white', backgroundColor: 'white', marginTop: '20px', marginBottom: '10px', width: '80%', height: '3px' }} />
                    <p style={{color: 'white'}}>Login to access dashboard</p>
                </Box>
            </Grid>
        </Grid>
    )
}
