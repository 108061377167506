import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'

import ContentSection from 'common/components/ContentSection'
import PropertyContracts from 'modules/contract/PropertyContracts'
import PropertyDetails from 'modules/property/PropertyDetails'
import { useProperty } from './useProperty'

export default function Property() {
    const { id } = useParams()
    const history = useHistory()
    const { property } = useProperty(id)

    function handleBack() {
        history.goBack()
    }

    return (
        <>
            <ArrowBackIcon style={{ cursor: 'pointer' }} onClick={handleBack} />
            <ContentSection header={property.name}>
                <PropertyDetails />
            </ContentSection>
            <ContentSection header="Prism Contracts">
                <PropertyContracts contracts={property.contracts} />
            </ContentSection>
        </>
    )
}
