import axios from './axios'

export default class AuthClient {
    constructor(firebase, token) {
        this.firebase = firebase

        // Axios
        this.axios = axios
        this.appbaseurl = process.env.REACT_APP_APP_BASE_URL
        this.axios.defaults.headers.common['authorization'] = `Bearer ${token}`
    }


    // Firebase sign in with email link, update the internal user object with updated firebase user and updated internal user
    signInWithEmailLink = (email, signinlink) => {
        return new Promise(async (success, failure) => {
            if (this.firebase.auth.isSignInWithEmailLink(signinlink)) {
                return this.firebase.auth.signInWithEmailLink(email, signinlink)
                    .then(async (res) => {
                        let idtoken = await res.user.getIdToken(true)
                        let existingsigninmethods = await this.firebase.auth.fetchSignInMethodsForEmail(res.user.email)
                        let haspassword = existingsigninmethods.includes(this.firebase.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)
                        let usertoken = await this.axios.post('user/register', { email: res.user.email, firebaseuserid: res.user.uid }, { headers: { common: { authorization: `Bearer ${idtoken}` } } })
                        let user = { token: usertoken.token, emailverified: res.user.emailVerified, haspassword, ...usertoken.user }
                        success(user)
                    })
                    .catch(err => {
                        if (['auth/expired-action-code', 'auth/invalid-action-code'].includes(err.code)) {
                            failure('Email sign in link has expired')
                        }
                    })
            }
        })
    }

    // Update the user's first name, last name, and replace firebase email sign in auth credential with an email && password auth credential
    updateUserAndPassword = (userprofile, password) => {
        return new Promise(async (success, failure) => {
            const newcredential = this.firebase.EmailAuthProvider.credential(userprofile.email, password)
            return this.firebase.auth.currentUser.unlink(this.firebase.EmailAuthProvider.PROVIDER_ID)
                .then(unlinkeduser => {
                    return unlinkeduser.linkWithCredential(newcredential)
                })
                .then(async (usercred) => {
                    let updateduser = await this.axios.post('user', userprofile)
                    success(updateduser)
                })
                .catch(err => {
                    failure(err)
                })
        })
    }

    // Simple update to internal user
    updateUser = user => {
        return new Promise(async (success, failure) => {
            return this.axios.post('user', user)
                .then(user => {
                    success(user)
                })
                .catch(err => {
                    // TODO - Handle Error Message
                    failure(err)
                })
        })
    }

    // Log in
    signInWithEmailAndPassword = (email, password) => {
        return new Promise(async (success, failure) => {
            return this.firebase.auth.signInWithEmailAndPassword(email, password)
                .then(async (res) => {
                    let idtoken = await res.user.getIdToken(true)
                    let usertoken = await this.axios.post('user/login', { email }, { headers: { authorization: `Bearer ${idtoken}` } })
                    let existingsigninmethods = await this.firebase.auth.fetchSignInMethodsForEmail(email)
                    let haspassword = existingsigninmethods.includes(this.firebase.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)
                    let user = { ...usertoken.user, token: usertoken.token, haspassword, emailverified: res.user.emailVerified }
                    success(user)
                })
                .catch(err => {
                    failure(err)
                })
        })
    }

    // Send firebase email sign in link (creates or logs in a verified user account after email link is opened)
    sendEmailSignInLink = (email, redirecturl = null) => {
        return new Promise(async (success, failure) => {
            // If the user already has a password, do not let them "Sign Up" through Email Sign In Link
            // TODO - Fix hard coded 'password' and reference this.firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
            let existingsigninmethods = await this.firebase.auth.fetchSignInMethodsForEmail(email)
            if (existingsigninmethods.includes(this.firebase.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD)) {
                failure({
                    code: 409,
                    message: 'Firebase user already exists'
                })
                return
            }

            if (redirecturl === null) {
                redirecturl = this.appbaseurl + '/registration/email?signinlink=true'
            }
            return this.firebase.auth.sendSignInLinkToEmail(email, { url: redirecturl, handleCodeInApp: true })
                .then(res => {
                    success(res)
                })
                .catch(err => {
                    failure(err)
                })
        })
    }

    // Sign out
    signOut = () => this.firebase.auth.signOut()
}
