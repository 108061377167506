import { Redirect, withRouter } from 'react-router-dom'
import { useAuth } from 'modules/authentication/AuthProvider'

function AuthenticationRedirection(props) {
    const { token } = useAuth()
    function redirect(path) {
        if (props.location.pathname !== path) {
            console.log('redirecting to ' + path)
            console.log('redirecting from ' + props.location.pathname)
        }
        return props.location.pathname === path
            ? null
            : <Redirect to={path} />
    }

    // If firebase user exists, but email is not verified, redirect them to verify their email
    // if (user.auth && !user.auth?.emailVerified)
    //     return redirect('/registration/email')

    // If firebase user exists, but they do not have a password, redirect them to update their password
    // if (user.auth && !user.auth?.hasPassword)
    //     return redirect('/registration/profile')

    // If the user has not accepted the current terms, redirect them to accept the terms and conditions
    // if (user.auth && !user.termsLastAccepted)
    //     return redirect('/registration/accept-terms')

    return null
}

export default withRouter(AuthenticationRedirection)
