import Grid from '@material-ui/core/Grid'
import PropertyList from 'modules/property/PropertyList'

export default function Properties() {
    return (
        <Grid
            container
            direction='column'
            alignItems='stretch'
        >
            <PropertyList />
        </Grid>
    )
}
