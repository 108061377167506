import { useContext, createContext } from 'react'
import PropertyClient from 'modules/api/PropertyClient'
import AuthClient from 'modules/api/AuthClient'
import { useAuth } from 'modules/authentication/AuthProvider'
import { useFirebase } from 'modules/authentication/FirebaseProvider'

// Context
const ApiContext = createContext(null)

// Hook
export const useApi = () => {
    return useContext(ApiContext)
}

// Provider ////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function ApiProvider({ children }) {
    const { firebase } = useFirebase()
    const { user } = useAuth()

    // const { user, firebase } = useAuth()
    // TODO - Be explicit on the methods / members we are exposing
    const apiclients = {
        propertyclient: new PropertyClient(user.token),
        authclient: new AuthClient(firebase, user.token)
    }

    return (
        <ApiContext.Provider value={{ ...apiclients }}>
            { children }
        </ApiContext.Provider>
    )
}
