import { useState, useEffect } from 'react'
import { useAuth } from 'modules/authentication/AuthProvider'
import { useHistory } from 'react-router-dom'
import { useApi } from 'modules/api/ApiProvider'

export default function useEmailSignInLink(email) {
    const [loading, setLoading] = useState(false)
    const [signinlinkexpired, setSignInLinkExpired] = useState(false)
    const { authclient: { signInWithEmailLink } } = useApi()
    const { setUser } = useAuth()
    const history = useHistory()

    useEffect(() => {
        let active = true

        // If the user just came through with an email verification link, sign in with the provided link
        const getQueryStringValue = (key) => decodeURIComponent(
            window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\\.\\+\\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1")
        )

        if (getQueryStringValue('signinlink') === 'true' && email) {
            setLoading(true)
            signInWithEmailLink(email, window.location.href)
                .then(user => {
                    if (active) {
                        setLoading(false)
                        setUser(user)
                        history.push('/registration/profile')
                    }
                })
                .catch(error => {
                    if (active) {
                        if (error.code === 410)
                            setSignInLinkExpired(true)
                        setLoading(false)
                    }
                })
        } else {
            new Promise(() => {})
        }

        return () => { active = false }

    // TODO - Fix this
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [email, history])

    return { loading, signinlinkexpired }
}
