import { Switch, Route } from 'react-router-dom'

import { LayoutRoute } from 'common/components/Routing'
import PaddedLayout from 'common/layouts/PaddedLayout'
import Contracts from './Contracts'
import ContractUpload from '../ContractUpload'

export default function PropertiesRoutes() {
    return (
        <Switch>
            <LayoutRoute exact path='/contracts' layout={PaddedLayout} component={<Contracts />} />
            <Route exact path='/contracts/upload' children={<ContractUpload />} />
        </Switch>
    )
}
