import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { Link } from 'common/components/Routing'

export default function PropertyContracts(props) {
    return (
        <Grid container>
            <Grid item xs={3}>
                <Box mx={1}>
                    <Link to="/contracts/upload">
                        <Paper elevation={0} style={{ backgroundColor: 'transparent', border: '1px dashed gray', marginTop: '1em' }}>
                            <p style={{ height: '23em', display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'gray' }}>Add Contract</p>
                        </Paper>
                    </Link>
                </Box>
            </Grid>
            { props.contracts.map((contract, index) => (
                <Grid key={index} item xs={3}>
                    <Box component={Paper} mx={1}>
                        <p style={{ height: '25em', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{contract}</p>
                    </Box>
                </Grid>
            )) }
        </Grid>
    )
}
