import { Switch } from 'react-router-dom'

import { LayoutRoute } from 'common/components/Routing'
import PaddedLayout from 'common/layouts/PaddedLayout'
import Properties from './Properties'
import Property from '../Property'
import PropertyCreate from '../PropertyCreate'

export default function PropertiesRoutes() {
    return (
        <Switch>
            <LayoutRoute exact path='/properties' layout={PaddedLayout} component={<Properties />} />
            <LayoutRoute exact path='/properties/create' layout={PaddedLayout} component={<PropertyCreate />} />
            <LayoutRoute exact path='/properties/:id' layout={PaddedLayout} component={<Property />} />
        </Switch>
    )
}
