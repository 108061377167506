import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Paper from '@material-ui/core/Paper'

export default function PropertyDetails() {
    return (
        <Grid container>
            <Grid item xs={12} md={4}>
                <Box component={Paper} mx={1}>
                    <h4 style={{ height: '20em', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Property Details</h4>
                </Box>
            </Grid>
            <Grid item xs={12} md={8}>
                <Box component={Paper} mx={1}>
                    <h4 style={{ height: '20em', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>Graphics</h4>
                </Box>
            </Grid>
        </Grid>
    )
}
