import TextField from 'common/components/TextField'
import Grid from '@material-ui/core/Grid'
import Button from 'common/components/Button'
import { useAuth } from 'modules/authentication/AuthProvider'
import { useHistory, Redirect } from 'react-router-dom'
import useForm from 'common/hooks/useForm'
import fields from './RegistrationCreateProfileFormFields'
import { useApi } from 'modules/api/ApiProvider'

export default function RegistrationCreateProfile(props) {
    const history = useHistory()
    const { user, setUser } = useAuth()
    const { authclient: { updateUserAndPassword } } = useApi()
    const { values, errors, handleChange, handleSubmit } = useForm(submit, fields, 'registrationcreateprofile')

    function submit() {
        // TODO - Form Validations
        updateUserAndPassword(Object.assign(user, { firstname: values['First Name'], lastname: values['Last Name'] }), values['Password'])
            .then(updateduser => {
                setUser(updateduser)
                history.push('/registration/accept-terms')
            })
            .catch(err => {
                if (err.code === 'auth/requires-recent-login' || err.code === 'auth/no-such-provider') {
                    props.openSnackbar('Your session expired while setting your password. Reauthenticate your email to continue.')
                    history.push('/registration/email')
                }
            })
    }

    // TODO - Disable Back Button if we have an authenticated user and we are just updating their password
    return (
        <Grid
            container
            direction="column"
            alignItems="center"
        >
            <TextField
                type="text"
                label="First Name"
                name="First Name"
                value={values['First Name'] || ''}
                onChange={handleChange}
                errormessage={errors.first('First Name')}
            />
            <TextField
                type="text"
                label="Last Name"
                name="Last Name"
                value={values['Last Name'] || ''}
                onChange={handleChange}
                errormessage={errors.first('Last Name')}
            />
            { !user.haspassword
            ? (
                <>
                    <TextField
                        type="password"
                        label="Password"
                        name="Password"
                        value={values['Password'] || ''}
                        onChange={handleChange}
                        errormessage={errors.first('Password')}
                    />
                    <TextField
                        type="password"
                        label="Password Confirm"
                        name="Password Confirmation"
                        value={values['Password Confirmation'] || ''}
                        onChange={handleChange}
                        errormessage={errors.first('Password Confirmation')}
                    />
                </>
            ) : null }
            <Button styletype='primary' onClick={handleSubmit}>Next</Button>
        </Grid>
    )
}
